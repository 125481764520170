import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clearError, login } from "../../actions/AuthAction";
import Swal from "sweetalert2";
import CustomPage from "./CustomPage";
import Logos from "../../Logo/Logos.svg";
import useLoading from "../CommonComponent/useLoading";
import Loader from "../Loader/Loader";


const Login = () => {
  const dispatch = useDispatch();
  const url = window.location.hostname;
  const { isLoading, startLoading, stopLoading } = useLoading();

  const { loading, error, isAuth, user } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  let navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      firebase_token: "",
      // access_url: url,
      access_url: "localhost",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Email is required !"),
      password: Yup.string().required("Email is required !"),
    }),
    onSubmit: async (values) => {



      startLoading()
      try {
        let res = await dispatch(login(values));
        if (res.status === 200) {
          const currentTime = new Date().getTime();
          localStorage.setItem("loginTime", currentTime);
          localStorage.setItem("token", res.temp_dict.token);
          localStorage.setItem("User", JSON.stringify(res.temp_dict.user_type));
          localStorage.setItem("school_user", JSON.stringify(res.temp_dict))
          if (res.temp_dict.user_type === "Admin") {
            navigate(`/${res.temp_dict.user_type}/admin-dashboard`);
            window.location.reload();
          } else {
            let isPassword = res.temp_dict.is_default_password;
            if (isPassword) {
              const result = await Swal.fire({
                title: "Current Password",
                text: "You want to Change the Current Password?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                allowOutsideClick: false,
              });
              if (result.isConfirmed) {
                navigate("/school/change-Password");
                window.location.reload();
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate(`/${res.temp_dict.user_type}/dashboard`);
                window.location.reload();
              }
            } else {
              navigate(`/${res.temp_dict.user_type}/dashboard`);
              window.location.reload();
            }
          }
        } else if (res.status === 403 || res.status === 503) {
          Swal.fire({
            text: res.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
          stopLoading()
      }
    },
  });


  

  if (isLoading) {
    return <Loader />;
  }


  return (
    <>
      <CustomPage>
        <div className="mt-1">
          <img
            src={Logos}
            alt=""
            style={{ width: "20%", margin: "auto", display: "block" }}
          />
          <div className="d-flex flex-column justify-content-center mt-1">
            <form
              style={{ alignItems: "center" }}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <h4
                className="text-center "
                style={{ color: "#19bb00", fontWeight: 700 }}
              >
                LOGIN{" "}
                <span
                  style={{ fontSize: 18, color: "#0E487A" }}
                  className="text-uppercase"
                >
                  {url?.split(".")[0]}
                </span>
              </h4>
              <div className="mt-4">
                <i
                  style={{
                    fontSize: 18,
                    position: "absolute",
                    zIndex: 1,
                    marginTop: 16,
                    marginLeft: 30,
                  }}
                  className="fa-solid fa-user"
                />
                <input
                  style={{ padding: "12px 12px 12px 60px", borderRadius: 50 }}
                  className="form-control"
                  type="email"
                  name="email"
                  value={validation.values.email || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  placeholder="Email"
                  autoComplete="off"
                  required
                />
              </div>
              <div className="mt-3">
                <i
                  style={{
                    fontSize: 18,
                    position: "absolute",
                    zIndex: 1,
                    marginTop: 16,
                    marginLeft: 30,
                  }}
                  className="fa-solid fa-lock"
                />

                <div style={{ position: "relative" }}>
                  <input
                    style={{ padding: "12px 12px 12px 60px", borderRadius: 50 }}
                    className="form-control"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={validation.values.password || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    placeholder="Password"
                    required
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "15px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <i className="fa fa-eye-slash"></i>
                    ) : (
                      <i className="fa fa-eye"></i>
                    )}
                  </span>
                </div>
              </div>
              <div className="text-center mx-auto mt-4">
                <button
                  type="submit"
                  style={{
                    borderRadius: 50,
                    backgroundColor: "#19bb00",
                    color: "#fff",
                    fontWeight: 600,
                  }}
                  className="btn  btn-block btn-lg mb-3"
                  title=""
                >
                  LOGIN
                </button>
              </div>

              <NavLink
                to="/forgot-password"
                className="mt-2 d-flex justify-content-center"
                style={{ textDecoration: "none" }}
              >
                Forgot{" "}
                <span style={{ fontWeight: 600 }} className="px-1">
                  {" "}
                  Password?
                </span>
              </NavLink>
            </form>
          </div>
        </div>
      </CustomPage>
    </>
  );
};

export default Login;
